<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Lista de cuestionarios
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.Titulo"
                            label="Nombre"
                            @change="loadData"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
    
            <v-btn
                to="/cuestionario-nuevo"
                color="primary"
                class="mt-5 mb-3">
                Nuevo
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarCuestionario"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
            </v-data-table>
           
        </v-container>
         
    </div>
   
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
               
                { text: 'Código', width: 75, sortable: false, value: 'CuestionarioId' },
                { text: 'Nombre', width:270, sortable: false, value: 'Titulo' },
                //{ text: 'Descripción', width: 300, sortable: false, value: 'Descripcion' },
                { text: 'Fecha de creación', width: 150, sortable: false, value: 'FechaCreacion' },
                //{ text: 'Fecha de borrado', width: 150, sortable: false, value: 'FechaBorrado' },
                { text: 'Visible', width: 75, sortable: false, value: 'Visible' },
            ],
            
            dtOptionsLoaded: false,
            filtros: {
                Titulo: ''
            }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            Titulo: this.filtros.Titulo,
            EmpId: this.empId
          };
          //llamada a la API
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/listAll", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    items,
                    total
                })
            }, 1000);
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar los cuestionarios.');
            }
          });
        });
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
        });
      },
      
      onClickRowEditarCuestionario(item) {
        this.$router.push('/cuestonario-editar/' + item.CuestionarioId);
      }
    }
  
})

</script>
